<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <!-- FORM START -->
      <div class="vx-row mb-2">
        <div class="vx-col w-full">
          <vs-input class="w-full" v-validate="'required'" name="police_no" label="Police No." v-model="police_no"></vs-input>
          <span class="text-danger text-sm" v-show="errors.has('police_no')">{{errors.first('police_no')}}</span>
        </div>
      </div>
      <div class="vx-row mb-2">
        <div class="vx-col w-full">
          <vs-input class="w-full" label="Vehicle No." v-model="code"></vs-input>
        </div>
      </div>
      <div class="vx-row mb-2">
        <div class="vx-col w-full">
          <vs-input class="w-full" label="Color" v-model="color"></vs-input>
        </div>
      </div>
      <div class="vx-row mb-2">
        <div class="vx-col w-full">
          <vs-input class="w-full" label="Chassis No." v-model="chassis_no"></vs-input>
        </div>
      </div>
      <div class="vx-row mb-2">
        <div class="vx-col w-full">
          <vs-input class="w-full" label="Machine No." v-model="machine_no"></vs-input>
        </div>
      </div>
      <div class="vx-row mb-2">
        <div class="vx-col w-full">
          <vs-input class="w-full" label="Fuel Capacity (litre)" @focus="$event.target.select()" v-model="bbm_capacity"></vs-input>
        </div>
      </div>
      <div class="vx-row mb-2">
        <div class="vx-col w-full">
          <browse-select-vehicle-type class="w-full" :value="vehicle_type_id" v-model="vehicle_type_id"></browse-select-vehicle-type>
          <!-- <browse-vehicle-type class="w-full" v-validate="'required'" name="vehicle_type_id" label="Vehicle Type" v-model="vehicle_type_id"></browse-vehicle-type>
          <span class="text-danger text-sm" v-show="errors.has('vehicle_type_id')">{{errors.first('vehicle_type_id')}}</span> -->
        </div>
      </div>
      <div class="vx-row mb-2">
        <div class="vx-col w-full">
          <browse-contact class="w-full" v-validate="'required'" name="owner_id" :isvendor="true" label="Owner / Vendor" v-model="owner_id"></browse-contact>
          <span class="text-danger text-sm" v-show="errors.has('owner_id')">{{errors.first('owner_id')}}</span>
        </div>
      </div>
      <div class="vx-row mb-2">
        <div class="vx-col w-full">
          <vs-input type="number" class="w-full" label="Daily Distance (km)" v-model="daily_km"></vs-input>
        </div>
      </div>
      <div class="vx-row mb-2">
        <div class="vx-col w-full">
          <vs-input type="number" class="w-full" label="Carrying Capacity" v-model="carrying_capacity"></vs-input>
        </div>
      </div>
      <div class="vx-row mb-2">
        <div class="vx-col w-full">
          <vs-input class="w-full" label="IMEI" v-model="imei"></vs-input>
        </div>
      </div>
      <div class="vx-row mt-4">
        <div class="vx-col w-full">
          <vs-checkbox class="w-full" v-model="is_active">Active</vs-checkbox>
        </div>
      </div>
      <!-- FORM END -->
    </div>
    <vs-divider></vs-divider>
    <div class="vx-col w-full flex">
      <vs-button @click="store" color="primary" icon-pack="feather" icon="icon-save">Save</vs-button>&nbsp;
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
// import BrowseVehicleType from '@browse/VehicleType.vue'
import BrowseSelectVehicleType from '@browse_select/VehicleType.vue'
import BrowseContact from '@browse/Contact.vue'
export default {
  props:{
    id: {
      type: String,
      default: null
    }
  },
  components:{
    // BrowseVehicleType,
    BrowseSelectVehicleType,
    BrowseContact
  },
  data(){
    return {
      police_no: "",
      code: "",
      owner_id: null,
      vehicle_type_id: null,
      chassis_no: "",
      machine_no: "",
      color: "",
      is_active: true,
      daily_km: 0,
      bbm_capacity: 0,
      carrying_capacity: 0,
      imei: null
    }
  },
  methods:{
    ...mapActions({
      dispatchStore: 'vehicles/store',
      dispatchUpdate: 'vehicles/update',
      dispatchShow: 'vehicles/show'
    }),
    store(){
      this.$validator.validateAll().then(async res => {
        if(!res) return false;
        let payload = {
          id: this.id,
          police_no: this.police_no,
          code: this.code,
          owner_id: this.owner_id,
          vehicle_type_id: this.vehicle_type_id,
          chassis_no: this.chassis_no,
          machine_no: this.machine_no,
          color: this.color,
          is_active: this.is_active,
          daily_km: this.daily_km,
          bbm_capacity: this.bbm_capacity,
          carrying_capacity: this.carrying_capacity,
          imei: this.imei
        }
        try {
          if (this.id) {
            let {data} = await this.dispatchUpdate(payload)
            this.$emit('on-success', data)            
          } else {
            let {data} = await this.dispatchStore(payload)
            this.$emit('on-success', data)
          }
        } catch (error) {
          this.$vs.notify({
            title: 'Oops!',
            text: error.data.message,
            color: 'danger'
          })
        }
      })
    },
    async getDetail(){
      let { data } = await this.dispatchShow(this.id)
      this.police_no = data.police_no
      this.code = data.code
      this.owner_id = data.owner_id
      this.vehicle_type_id = data.vehicle_type_id
      this.chassis_no = data.chassis_no
      this.machine_no = data.machine_no
      this.color = data.color
      this.is_active = data.is_active
      this.daily_km = data.daily_km
      this.bbm_capacity = data.bbm_capacity
      this.carrying_capacity = data.carrying_capacity
      this.imei = data.imei
    }
  },
  mounted(){
    // console.log(this.ids)
    if (this.id) {
      this.getDetail()
    }
  }
}
</script>

<style>

</style>